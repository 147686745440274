<template>
  <div>
    <v-chip
      v-if="principal"
      class="ml-1 mt-1"
      small
      :color="CLIENTE_CUENTA.colores.principal"
      label
      dark
    >
      Principal
    </v-chip>
  </div>
</template>

<script>
import { CLIENTE_CUENTA } from '@/utils/consts'

export default {
  props: {
    principal: {
      type: Boolean,
    },
  },
  data () {
    return {
      CLIENTE_CUENTA,
    }
  },
}
</script>
